//
// _dropdown.scss
//
.accordion{
  &-header{
    transition: ease-in-out;
    &:hover, &.active{
      background:rgba(0,0,0,0.03)
    }
  
  }
}
.dropdown button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dropdown-icon{
  position: absolute;
  cursor: pointer;
  top: 20%;
  right: 2.5%;
  font-size: 0.8em;
  transition: ease-in-out 0.3s;
  background: rgba(0,0,0,0.05);
  border-radius: 2px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center
}
.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;
  height: 100px;
  width: 100% !important;
  overflow: hidden;
  overflow-y: auto;
  &.show {
    top: 100% !important;
  }
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}
