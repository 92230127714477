.file-upload {
    &-container {
        position: relative;
        width: 100%;
        min-height: 60px;
    }

    width: 100%;
    height: 100%;
    &er {
        width: 100%;
        height: 100%;
        border: 2px dotted $gray-400;
        text-align: center;
        i{
            font-size: 4em;
            color:$gray-700
        }
        border-radius: $border-radius;

        small,p{
            color:$gray-500
        }
    }
    &ed {
        width: 100%;
        height: 100%;
        border: 2px dotted $primary;
        border-radius: $border-radius;
        i{
            font-size: 3em;
        }
        small{
            color:$gray-500

        }
    }
}
