.logoBody{
    height:200px;
}
.icon, .icon-active {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f8f8fb;
    position: relative;
    right: 25px;
    top: 35px;
    transition: all .2s linear 
}
.icon:hover {
    transform: rotatez(90deg);
    transition: all .2s linear ;
    transition-delay: .5s;
}
.icon-active {
    transform: rotatez(45deg);
}
.minusIconBody {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f8f8fb;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 25px;
    top: 35px;
    transition: all .2s linear ;
}
.minusIconBody:hover {
    transition: all .2s linear ;
    transform: rotatez(-15deg);
}
.minusIcon {
    width: 20px;
    height: 3px;
    border-radius: 2px;
    background-color: #f5ba58;
    position: relative;
    top: 7px;
}
.iconBody {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e1e6ea;
    overflow: hidden;
    transition: 1s all linear;
}
.updateInstitutionIconBody {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e1e6ea;
    border-radius: 100%;
    overflow: hidden;
    transition: 1s all linear;
}
.institutionLogoBody {
    display: flex;
    gap: 80px;
    flex-direction: row;
    align-items: center;
}
.logoOptions {
    width: 150px;
    position: relative;
    top: 80px;
    right: 50px;
    transition: all .4s linear ;
}
.logoOptionsHidden {
    width: 0px;
    position: relative;
    top: 80px;
    right: 50px;
    transition: all .2s linear ;
}
.logoOpton {
    height: 30px;
    white-space: nowrap;
    padding-top: 3px;
    padding-bottom: 3px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #f5ba58;
    font-weight: bold;
    transition: .1s all ease-in;
    z-index: 3;
}
.logoOpton:hover {
    background-color: #f5ba5880;
    transition: .1s all ease-in;
}
.mainSmall {
    height: 120px;
    transition: .5s all linear;
    display: flex;
    justify-content: end;
}
.mainBig {
    height: 182px;
    transition: .5s all linear;
    display: flex;
    justify-content: end;
}