//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
  &.expand-on-hover{
    &:hover{
      transform: scale(1.04);
      transition: 0.1s ease-in-out;
    }
  }
}

.card-drop {
  color: $body-color;
}
svg g .apexcharts-datalabels-group text.apexcharts-text{
  font-family: inherit !important;
  &:nth-child(1){
  fill: $gray-800 !important;
  }
}
.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
  border-bottom: 1px solid #ececec;
  padding-bottom: 5%;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

