@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

ul {
  list-style-type: none !important;
}
ul.accordion {
  width: 100%;
  padding-left: 10px;
}
.functions li {
  .title {
    height: 40px;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    // display: flex;
    // align-items: center;
    &:hover {
      cursor: pointer;
      color: #a6b0cf;
      background-color: rgba(191, 200, 226, 0.05);
      width: 100%;
    }
    .row {
      width: 100%;
    }
  }
  .collapse {
    // width: 80%;
    margin: 20px auto;
  }

  input[type="checkbox"] {
    transform: scale(0.9);
  }
}
button a {
  color: inherit !important;
}
img {
  width: 100%;
  height: 100%;
}
.function-item {
  margin-bottom: 10px;
}
input.error + .span {
  position: relative;
  width: 100%;
}
input.error + .span::after {
  content: attr(data-error);
  position: absolute;
  right: 0;
  // top: -50px;
  border: 1px solid $warning;
  border-radius: $border-radius;
  padding: 7px 10px;
  color: $secondary;
  // height: 100%;
  // width: 30px;
  // min-width: 50%;
  // max-width: 150px;
  font-size: x-small;
  background: #f1b44c60;
  z-index: 99;
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.rotating_fast {
  -webkit-animation: rotating 0.5s linear infinite;
  -moz-animation: rotating 0.5s linear infinite;
  -ms-animation: rotating 0.5s linear infinite;
  -o-animation: rotating 0.5s linear infinite;
  animation: rotating 0.5s linear infinite;
}

// .scrolling-wrapper {
//   overflow-x: scroll;
//   overflow-y: hidden;
//   white-space: nowrap;

//   .scroll-card {
//     display: inline-block;
//   }
// }

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .scrolling_card {
    flex: 0 0 auto;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.map-search {
  form .row {
    position: absolute;
    z-index: 6;
    width: 100%;
    padding: 20px;
    display: flex !important;
    justify-content: flex-end !important;
  }
}
