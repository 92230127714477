//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .divider-top-#{$color} {
    border-top-width: 4px;
    border-color: $value;
  }
}
