// @import '../../../assets/scss/variables';
// .marker{
//     width: 128px;
//     height: 128px;
//     border-radius: 128px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #b3badd90;
//     border: 1px solid #556ee6;
//     .img{
//     width: 10px;
//     height: 10px;
//     }
// }
.mapboxgl-popup {
  // &-tip{
  //     background: #b3badd90 !important;
  // }
  // &-content{
  //     background: #b3badd90 !important;
  // }
  width: 200px;
  z-index: 9 !important;
}
.marker {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  animation: out 3s infinite ease-out;
  background: #e83e8c;
  // #556ee6;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -25px 0px 0px -25px;
  cursor: pointer;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
}

@keyframes out {
  0% {
    transform: scale(1);
  }
  50% {
    box-shadow: 0px 0px 0px 0px #e83e8c30;
    //  #b3badd90;
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.4);
  }
  100% {
    box-shadow: 0px 0px 0px 60px rgba(255, 255, 255, 0);
    transform: scale(1.1);
  }
}
